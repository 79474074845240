import { Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom, map } from "rxjs";

import { Constants } from "../../../../constants/constants";
import { UsersService } from "../users.service";
import { User } from "src/app/models/shared";
import { TitleService } from "../../../../services/title.service";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { UserGroupsService } from "../../user-groups/user-groups.service";
import { RolesService } from "../../roles/roles.service";

enum AuthTypeOptions {
    ZEN_MASTER = "zm",
    SSO = "sso"
}
@Component({
    selector: "app-user-form",
    templateUrl: "./user-form.component.html"
})
export class UserFormComponent implements OnInit {
    authTypeOptions = AuthTypeOptions;
    user = new User();
    userId: number;
    action: string;

    submitted = false;
    isEdit = false;
    loading = true;
    saving = false;

    constants = Constants;

    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private usersService = inject(UsersService);
    private titleService = inject(TitleService);
    private formBuilderService = inject(FormBuilder);
    private userGroupService = inject(UserGroupsService);
    private rolesService = inject(RolesService);

    userGroups$ = this.userGroupService.userGroups;
    roles$ = this.rolesService.roles;
    userNames$ = this.usersService.users.pipe(
        map(users => (this.isEdit ? users.filter(u => u.id !== this.userId).map(u => u.name) : users.map(u => u.name)))
    );
    authTypeControl = new FormControl<AuthTypeOptions>(AuthTypeOptions.ZEN_MASTER);

    form = this.formBuilderService.group({
        name: ["", [Validators.required, Validators.minLength(2), Validators.pattern(this.constants.validators.name)]],
        email: ["", [Validators.required, Validators.minLength(2)]],
        password: [undefined as string, [Validators.required, Validators.pattern(this.constants.validators.password)]],
        confirmPassword: [undefined as string, [Validators.required]],
        must_reset: [false],
        is_admin: [false],
        is_enabled: [true],
        is_objects_manager: [false],
        is_incident_manager: [false],
        group_ids: [[] as number[]],
        role_ids: [[] as number[]]
    });

    private prepForm() {
        if (this.isEdit) {
            this.authTypeControl.setValue(this.user.sso_id ? AuthTypeOptions.SSO : AuthTypeOptions.ZEN_MASTER);
            this.authTypeControl.disable();
            this.form.patchValue({
                name: this.user.name,
                email: this.user.email,
                must_reset: !!this.user.must_reset,
                is_admin: !!this.user.is_admin,
                is_enabled: !!this.user.is_enabled,
                is_objects_manager: !!this.user.is_objects_manager,
                is_incident_manager: this.user.is_incident_manager,
                group_ids: this.user.groups ? this.user.groups.map(g => g.id) : [],
                role_ids: this.user.roles ? this.user.roles.map(r => r.id) : []
            });
            this.form.controls.password.removeValidators(Validators.required);
            this.form.controls.confirmPassword.removeValidators(Validators.required);
            if (this.authTypeControl.value === AuthTypeOptions.SSO) {
                this.form.controls.name.disable();
                this.form.controls.email.disable();
            }
        }

        if (!this.isEdit) this.form.controls.is_enabled.setValue(true);

        // Set Title
        this.titleService.setTitle("USER", this.action, this.user);
        // UGLY hack to prevent chrome from autofilling those fields
        setTimeout(() => {
            this.form.controls.email.setValue(this.form.controls.email.value);
            this.form.controls.password.setValue(this.form.controls.password.value);
        }, 2000);
    }

    async ngOnInit() {
        this.userGroupService.refreshUserGroups();
        this.rolesService.refreshRoles();
        this.usersService.refreshUsers();
        const params = this.route.snapshot.params;
        this.userId = parseInt(params.id, 10);
        this.action = params.action;

        if (this.userId) {
            this.isEdit = true;
            this.user = await firstValueFrom(this.usersService.refreshUser(this.userId, true));
        }
        this.loading = false;

        this.authTypeControl.valueChanges.subscribe(value => {
            if (value === AuthTypeOptions.ZEN_MASTER) {
                this.form.controls.password.enable({ onlySelf: true });
                this.form.controls.confirmPassword.enable();
                this.form.controls.must_reset.enable();
            }
            if (value === AuthTypeOptions.SSO) {
                this.form.controls.password.disable();
                this.form.controls.confirmPassword.disable();
                this.form.controls.must_reset.disable();
            }
        });

        this.prepForm();
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            ...this.form.value,
            sso_id: this.authTypeControl.value === "sso" ? "Pending" : undefined,
            password:
                this.authTypeControl.value === AuthTypeOptions.ZEN_MASTER &&
                (!this.isEdit || this.form.controls.password.value)
                    ? this.form.controls.password.value
                    : undefined
        };
        if (this.isEdit) {
            try {
                const result = await this.usersService.updateUser(this.userId, model);
                if (result) {
                    this.usersService.refreshUser(this.userId, true);
                    this.router.navigate([Constants.urls.accountManagement.users, this.userId]);
                }
            } catch (_) {
                this.saving = false;
            }
        } else {
            try {
                const result = await this.usersService.addUser(model);
                if (result) this.router.navigate([Constants.urls.accountManagement.users, result.id]);
            } catch (_) {
                this.saving = false;
            }
        }
    }
}
