import { Component, OnInit, OnDestroy, ViewChildren, QueryList } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { map, take } from "rxjs/operators";
import * as _ from "lodash";

import { Constants } from "../../../../constants/constants";
import { ModalService } from "../../../../components/shared/modals/modal.service";

import { UsersService } from "../users.service";
import { Role, RolePermission, User } from "src/app/models/shared";
import { SortEvent, NgbSortableHeader } from "src/app/directives/sortable.directive";
import { SharedService } from "src/app/services/shared.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleService } from "../../../../services/title.service";
import { RolesService } from "../../roles/roles.service";

@Component({
    selector: "app-user",
    templateUrl: "./user.component.html"
})
export class UserComponent implements OnInit, OnDestroy {
    user = new User();
    userId: number;
    userRoles: Role[];

    @ViewChildren(NgbSortableHeader) headers: QueryList<NgbSortableHeader>;
    sortedByColumn: string;
    sortedByText: string;
    sortColumn: string;
    sortDirection: string;

    impersonating$: Observable<boolean>;
    isZixiAdmin$: Observable<number>;

    private usersSubscription: Subscription;
    constants = Constants;

    loading = true;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private usersService: UsersService,
        private rolesService: RolesService,
        private modalService: ModalService,
        private sharedService: SharedService,
        private mixpanelService: MixpanelService,
        private titleService: TitleService
    ) {
        this.route.paramMap.subscribe(params => {
            this.userId = parseInt(params.get("id"), 10);
            if (!this.user) return this.cancel();

            this.loading = true;

            this.usersService
                .refreshUser(this.userId)
                .pipe(take(1))
                .subscribe(user => {
                    this.prepUser(user);
                    this.loading = false;
                });
        });
    }

    ngOnInit() {
        this.usersSubscription = this.usersService.users.subscribe(users => {
            const user = users.find(u => u.id === this.userId);
            if (user) this.prepUser(user);
        });

        this.impersonating$ = this.usersService.user.pipe(map(u => u.impersonating));
        this.isZixiAdmin$ = this.usersService.user.pipe(map(u => u.is_zixi_admin));
    }

    ngOnDestroy() {
        if (this.usersSubscription) this.usersSubscription.unsubscribe();
    }

    private prepUser(user: User) {
        const userRoles: Partial<Role> & { tagName: string; source: string; permissions: RolePermission[] }[] = [];

        for (const role of user.roles || []) {
            userRoles.push({
                ...this.rolesService.prepRole(role, false),
                tagName: role.resourceTag.name,
                source: "User",
                permissions: role.permissions
            });
        }

        for (const group of user.groups || []) {
            for (const role of group.roles) {
                userRoles.push({ ...role, tagName: role.resourceTag.name, source: group.name });
            }
        }

        const uniqueRoles = _.uniqBy(userRoles, "id");

        this.userRoles = uniqueRoles as unknown as Role[];

        this.user = Object.assign({}, user);

        // Set Title
        this.titleService.setTitle("USER", "", this.user);
    }

    cancel() {
        this.gotoUsers();
    }

    gotoUsers() {
        this.router.navigate([Constants.urls.accountManagement.users]);
    }

    async deleteUser() {
        await this.modalService.confirm(
            "DELETE",
            "USER",
            async () => {
                const id = this.user.id;
                const result = await this.usersService.deleteUser(this.user);
                if (result) {
                    if (this.usersSubscription) {
                        this.usersSubscription.unsubscribe();
                        this.usersSubscription = null;
                    }
                    this.mixpanelService.sendEvent("delete usser", { id });
                    this.usersService.refreshUsers(true);
                    this.gotoUsers();
                } else {
                    return false;
                }
            },
            `${this.user.name} (${this.user.email})`
        );
    }

    edit(user: User): void {
        this.router.navigate([Constants.urls.accountManagement.users, user.id, "edit"]);
    }

    onSort({ column, direction }: SortEvent, name: string) {
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = "";
            } else {
                header.direction = direction;
            }
        });
        this.sortedByColumn = column;
        this.sortedByText = name;
        this.sortColumn = column;
        this.sortDirection = direction;
    }
}
