<app-form-wrapper titleMsg="USER" [formGroup]="form" [loading]="loading" [isEdit]="isEdit">
    <!-- Name -->
    <app-input-generic-field
        type="text"
        [formControl]="form.controls.name"
        [textFieldName]="'NAME' | translate"
        [isParentFormSubmitted]="submitted"
        [checkDuplicateNames]="userNames$ | async"
    ></app-input-generic-field>

    <!-- EMAIL -->
    <app-input-generic-field
        type="text"
        autoComplete="off"
        [formControl]="form.controls.email"
        [textFieldName]="'EMAIL' | translate"
        [isParentFormSubmitted]="submitted"
    ></app-input-generic-field>

    <!-- AUTH TYPE -->
    <app-radio-input
        *ngIf="!isEdit"
        label="{{ 'AUTHENTICATION' | translate }} {{ 'TYPE' | translate }}"
        [inputKeyAndValues]="authTypeOptions"
        [formControl]="authTypeControl"
        [isParentFormSubmitted]="submitted"
    ></app-radio-input>

    <!-- New Password -->
    <app-input-generic-field
        type="password"
        textFieldName="{{ 'NEW' | translate }} {{ 'PASSWORD' | translate }}"
        patternErrorMessage="{{ 'PASSWORD_MUST_BE' | translate }}"
        [ngClass]="{ 'd-none': authTypeControl.value !== 'zm' }"
        [formControl]="form.controls.password"
        [underLabel]="isEdit && 'BLANK_SECRET_EDIT' | translate"
        [isParentFormSubmitted]="submitted"
        [passwordTip]="true"
    ></app-input-generic-field>

    <!-- Confirm Password -->
    <app-input-generic-field
        type="password"
        textFieldName="{{ 'CONFIRM' | translate }} {{ 'NEW' | translate }} {{ 'PASSWORD' | translate }}"
        [ngClass]="{ 'd-none': authTypeControl.value !== 'zm' }"
        [formControl]="form.controls.confirmPassword"
        [isParentFormSubmitted]="submitted"
    ></app-input-generic-field>

    <!-- Force password reset -->
    <app-checkbox-field
        [ngClass]="{ 'd-none': authTypeControl.value !== 'zm' }"
        [label]="'MUST_RESET_PASSWORD' | translate"
        [formControl]="form.controls.must_reset"
        id="must_reset2"
    ></app-checkbox-field>

    <!-- Enabled -->
    <app-checkbox-field [label]="'ENABLED' | translate" [formControl]="form.controls.is_enabled" id="is_enabled2"></app-checkbox-field>

    <!-- Administrator -->
    <app-checkbox-field [label]="'ACCOUNT_ADMINISTRATOR' | translate" [formControl]="form.controls.is_admin" id="is_admin2"></app-checkbox-field>

    <!-- Objects Manager -->
    <app-checkbox-field [label]="'ADMINISTRATOR' | translate" [formControl]="form.controls.is_objects_manager" id="is_objects_manager2"></app-checkbox-field>

    <!-- Incident Manager -->
    <app-checkbox-field
        [label]="'INCIDENT_MANAGER' | translate"
        [formControl]="form.controls.is_incident_manager"
        id="is_incident_manager2"
    ></app-checkbox-field>

    <!-- GROUPS -->
    <app-select-generic-field
        title="{{ 'GROUPS' | translate }}"
        valueToBind="id"
        [formControl]="form.controls.group_ids"
        placeHolder="{{ 'GROUPS' | translate }}"
        [isParentFormSubmitted]="submitted"
        [items]="userGroups$ | async"
        [multiple]="true"
        [clearable]="true"
    >
    </app-select-generic-field>

    <!-- Roles -->
    <app-select-generic-field
        title="{{ 'ROLES' | translate }}"
        valueToBind="id"
        [formControl]="form.controls.role_ids"
        placeHolder="{{ 'ROLES' | translate }}"
        [isParentFormSubmitted]="submitted"
        [items]="roles$ | async"
        [clearable]="true"
        [multiple]="true"
    >
    </app-select-generic-field>

    <app-error [marginBottom]="true" [marginTop]="false"></app-error>

    <app-zx-form-bottom-buttons
        [isFormValid]="form.valid"
        [(submitted)]="submitted"
        (submit)="onSubmit()"
        [isHorizontalRowNeeded]="true"
        [cancelUrlNavigate]="constants.urls.accountManagement.users"
        [isSaving]="saving"
        [isEdit]="isEdit"
    ></app-zx-form-bottom-buttons>
</app-form-wrapper>
